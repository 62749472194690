var Sortable = require('sortablejs');
var ImageTools = require('vendor/image_tools');

var appendFile, setPositions, validateType;

setPositions = function($list) {
  return $list.find('.c-images__item').each((function(_this) {
    return function(index, item) {
      return $(item).find('.c-images__input-position').val(index + 1);
    };
  })(this));
};

appendFile = function($component, $list, file, counter, model) {
  var $container, $imagePreview, filename;
  filename = file.name.split('.');
  filename.pop();
  filename = filename.join('.');
  $imagePreview = $('<div class="c-thumb c-thumb--with-spinner"></div>');
  $container = $("<div class=\"c-images__item\">\n  <input type=\"hidden\" name=\"" + model + "[images_attributes][" + counter + "][id]\" class=\"c-images__input-id\">\n  <div class=\"row align-items-center\">\n    <div class=\"col-auto\"></div>\n    <div class=\"col\">\n      <input type=\"hidden\" class=\"c-images__input-destroy\" name=\"" + model + "[images_attributes][" + counter + "][_destroy]\" value=\"0\">\n      <input type=\"hidden\" class=\"c-images__input-file-b64\" name=\"" + model + "[images_attributes][" + counter + "][file_b64]\">\n      <input type=\"hidden\" class=\"c-images__input-file-filename\" name=\"" + model + "[images_attributes][" + counter + "][file_filename]\">\n      <input type=\"hidden\" class=\"c-images__input-position\" name=\"" + model + "[images_attributes][" + counter + "][position]\" value=\"" + ($list.find('.c-images__item').length + 1) + "\">\n      <div class=\"row align-items-end\">\n        <div class=\"col\">\n          <div class=\"form-group mt-3\">\n            <label>Description <small class=\"text-muted font-italic ml-2\">utile aux personnes malvoyantes</small></label>\n            <input autocomplete=\"off\" class=\"form-control form-control-sm\" type=\"text\" name=\"" + model + "[images_attributes][" + counter + "][description]\">\n          </div>\n        </div>\n        <div class=\"col-auto\">\n          <i class=\"material-icons c-images__destroy btn text-danger mb-3\">clear</i>\n          <i class=\"material-icons c-images__handle\">drag_handle</i>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>");
  $container.find('.col-auto').eq(0).append($imagePreview);
  $container.find('.form-control').val(filename);
  $list.append($container);
  return ImageTools.resize(file, {
    width: $component.data('width'),
    height: $component.data('height')
  }, (function(_this) {
    return function(blob, didItResize) {
      var objectUrl, reader;
      objectUrl = window.URL.createObjectURL(blob);
      reader = new FileReader();
      reader.readAsDataURL(blob);
      return reader.onloadend = function() {
        $container.find('.c-images__input-file-filename').val(file.name);
        $container.find('.c-images__input-file-b64').val(reader.result);
        $imagePreview.css('background-image', 'url(' + objectUrl + ')');
        return $imagePreview[0].classList.remove('c-thumb--with-spinner');
      };
    };
  })(this));
};

validateType = function(file, accept) {
  var i, len, type;
  for (i = 0, len = accept.length; i < len; i++) {
    type = accept[i];
    type = type.replace(new RegExp(/\*/, 'g'), '.*');
    if (new RegExp(type).test(file.type)) {
      return true;
    }
  }
  return false;
};

document.addEventListener("turbolinks:load", function() {
  return document.querySelectorAll('.c-images').forEach(function(component) {
    var $component, $dropzone, $dropzoneInput, $list, accept, itemsCounter, model;
    $component = $(component);
    $list = $component.find('.c-images__list');
    $dropzone = $component.find('.c-images__dropzone');
    $dropzoneInput = $component.find('.c-images__dropzone-input');
    itemsCounter = $list.find('.c-images__item').length;
    model = $component.data('model');
    accept = $component.data('accept');
    Sortable.create($list[0], {
      handle: '.c-images__handle',
      animation: 150,
      onSort: (function(_this) {
        return function(event) {
          return setPositions($list);
        };
      })(this)
    });
    $list.on('click', '.c-images__destroy', function() {
      var $this, $wrap;
      $this = $(this);
      $wrap = $this.parents('.c-images__item');
      if ($wrap.find('.c-images__input-id').val()) {
        $wrap.find('.c-images__input-destroy').val(1);
        return $wrap.hide();
      } else {
        return $wrap.remove();
      }
    });
    $dropzoneInput.on("dragenter", (function(_this) {
      return function(event) {
        return $dropzone.addClass('c-images__dropzone--hover');
      };
    })(this));
    $dropzoneInput.on("dragleave", (function(_this) {
      return function(event) {
        return $dropzone.removeClass('c-images__dropzone--hover');
      };
    })(this));
    return $dropzoneInput.on('change', (function(_this) {
      return function(event) {
        var file, i, len, ref;
        $dropzone.removeClass('c-images__dropzone--hover');
        ref = event.target.files;
        for (i = 0, len = ref.length; i < len; i++) {
          file = ref[i];
          if (accept && !validateType(file, accept)) {
            break;
          }
          appendFile($component, $list, file, itemsCounter, model);
          itemsCounter++;
        }
        return $dropzoneInput.val('');
      };
    })(this));
  });
});

document.addEventListener('turbolinks:load', function() {
  var navNode, overlayNode;
  navNode = document.querySelector('.l-nav');
  overlayNode = document.querySelector('.l-nav__overlay');
  logoNode = document.querySelector('.l-header__logo-wrap');

  document.querySelector('.l-nav__toggle-btn').addEventListener('click', function() {
    navNode.classList.toggle('is-visible');
    overlayNode.classList.toggle('is-visible');
    logoNode.classList.toggle('is-visible');
  });

  overlayNode.addEventListener('click', function() {
    navNode.classList.remove('is-visible');
    overlayNode.classList.remove('is-visible');
    logoNode.classList.remove('is-visible');
  });

  document.querySelectorAll('.l-nav__item--with-dropdown').forEach(function(item) {
    item.addEventListener('mouseenter', function(event) {
      item.classList.add('is-visible');
    });
    item.addEventListener('mouseleave', function(event) {
      item.classList.remove('is-visible');
    });
    item.addEventListener('click', function(event) {
      item.classList.toggle('is-visible');
    });
  });
});

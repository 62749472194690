var tarifs = {
  1: {
    1: 116,
    2: 116,
    3: 116,
    4: 95,
    5: 95,
    6: 74,
    7: 74,
    8: 74,
    9: 74,
    10: 95,
    11: 95,
    12: 116,
  },
  2: {
    1: 312,
    2: 293,
    3: 274,
    4: 236,
    5: 217,
    6: 198,
    7: 198,
    8: 217,
    9: 236,
    10: 274,
    11: 293,
    12: 312,
  },
  3: {
    1: 487,
    2: 454,
    3: 420,
    4: 386,
    5: 386,
    6: 386,
    7: 420,
    8: 454,
    9: 487,
    10: 521,
    11: 521,
    12: 521,
  },
  4: {
    1: 760,
    2: 760,
    3: 760,
    4: 760,
    5: 760,
    6: 760,
    7: 760,
    8: 760,
    9: 760,
    10: 760,
    11: 760,
    12: 760,
  },
};

document.addEventListener('turbolinks:load', function() {
  var select = document.querySelector('.v-tarifs-select');
  if (!select) return;

  var prices = document.querySelectorAll('.v-tarifs-price');
  var price1 = document.querySelector('.v-tarifs-price-1');
  var price2 = document.querySelector('.v-tarifs-price-2');
  var price3 = document.querySelector('.v-tarifs-price-3');
  var price4 = document.querySelector('.v-tarifs-price-4');

  updatePrice = (elem, abo, month) => {
    elem.innerHTML = tarifs[abo][month];
  }

  updatePrices = () => {
    let activeMonth = select.querySelector('option:checked').innerHTML.toLowerCase();
    updatePrice(price1, 1, select.value);
    updatePrice(price2, 2, select.value);
    updatePrice(price3, 3, select.value);
    updatePrice(price4, 4, select.value);
    prices.forEach((price) => {
      price.classList.add('do-animate');
      setTimeout(() => { price.classList.remove('do-animate') }, 500)
    });
    document.querySelectorAll('.v-tarifs-price__month').forEach(function(elem) {
      elem.innerHTML = activeMonth;
    });
  }

  updatePrices();
  select.addEventListener('change', elem => updatePrices());
});

require.context('../images', true);
import './stylesheets.scss';

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

// import Alert from 'bootstrap/js/src/alert'
// import Button from 'bootstrap/js/src/button'
// import Carousel from './carousel'
// import Collapse from './collapse'
// import Dropdown from './dropdown'
// import Modal from './modal'
// import Popover from './popover'
// import Scrollspy from './scrollspy'
// import Tab from './tab'
// import Tooltip from './tooltip'
// import Util from 'bootstrap/js/src/util'

require('jquery-ujs');

require('layout/nav');
require('components/attachments');
require('components/images');
require('components/photoswipe');
require('components/piwik');

require('views/tarifs');
require('views/smoothscroll');

var Turbolinks = require("turbolinks");
Turbolinks.start();

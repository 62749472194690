var Sortable = require('sortablejs');

var appendFile, setPositions, validateType;

setPositions = function($list) {
  return $list.find('.c-attachments__item').each((function(_this) {
    return function(index, item) {
      return $(item).find('.c-attachments__input-position').val(index + 1);
    };
  })(this));
};

appendFile = function($component, $list, file, counter, model) {
  var $container, reader, size;
  size = parseInt(file.size / 1000);
  if (size < 1000) {
    size = size + " ko";
  } else {
    size = (Math.round(size / 1000 * 10) / 10) + " Mo";
  }
  $container = $("<div class=\"c-attachments__item\">\n  <input type=\"hidden\" name=\"" + model + "[attachments_attributes][" + counter + "][id]\" class=\"c-attachments__input-id\">\n  <div class=\"row no-gutters align-items-center\">\n    <div class=\"col-auto pr-1\"><i class=\"material-icons\">attach_file</i></div>\n    <div class=\"col\">\n      <input type=\"hidden\" class=\"c-attachments__input-destroy\" name=\"" + model + "[attachments_attributes][" + counter + "][_destroy]\" value=\"0\">\n      <input type=\"hidden\" class=\"c-attachments__input-file-b64\" name=\"" + model + "[attachments_attributes][" + counter + "][file_b64]\">\n      <input type=\"hidden\" class=\"c-attachments__input-file-filename\" name=\"" + model + "[attachments_attributes][" + counter + "][file_filename]\">\n      <input type=\"hidden\" class=\"c-attachments__input-position\" name=\"" + model + "[attachments_attributes][" + counter + "][position]\" value=\"" + ($list.find('.c-attachments__item').length + 1) + "\">\n      <div class=\"row align-items-end\">\n        <div class=\"col\">\n          <div class=\"form-group mt-3\">\n            " + file.name + " <small class=\"text-muted ml-2\">" + size + "</small>\n          </div>\n        </div>\n        <div class=\"col-auto\">\n          <i class=\"material-icons c-attachments__destroy btn text-danger mb-3\">clear</i>\n          <i class=\"material-icons c-attachments__handle\">drag_handle</i>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>");
  reader = new FileReader();
  reader.readAsDataURL(file);
  return reader.onload = (function(_this) {
    return function() {
      $container.find('.c-attachments__input-file-filename').val(file.name);
      $container.find('.c-attachments__input-file-b64').val(reader.result);
      return $list.append($container);
    };
  })(this);
};

validateType = function(file, accept) {
  var i, len, type;
  for (i = 0, len = accept.length; i < len; i++) {
    type = accept[i];
    type = type.replace(new RegExp(/\*/, 'g'), '.*');
    if (new RegExp(type).test(file.type)) {
      return true;
    }
  }
  return false;
};

document.addEventListener("turbolinks:load", function() {
  return document.querySelectorAll('.c-attachments').forEach(function(component) {
    var $component, $dropzone, $dropzoneInput, $list, accept, itemsCounter, maxSize, model;
    $component = $(component);
    $list = $component.find('.c-attachments__list');
    $dropzone = $component.find('.c-attachments__dropzone');
    $dropzoneInput = $component.find('.c-attachments__dropzone-input');
    itemsCounter = $list.find('.c-attachments__item').length;
    model = $component.data('model');
    accept = $component.data('accept');
    maxSize = $component.data('max-size');
    Sortable.create($list[0], {
      handle: '.c-attachments__handle',
      animation: 150,
      onSort: (function(_this) {
        return function(event) {
          return setPositions($list);
        };
      })(this)
    });
    $list.on('click', '.c-attachments__destroy', function() {
      var $this, $wrap;
      $this = $(this);
      $wrap = $this.parents('.c-attachments__item');
      if ($wrap.find('.c-attachments__input-id').val()) {
        $wrap.find('.c-attachments__input-destroy').val(1);
        return $wrap.hide();
      } else {
        return $wrap.remove();
      }
    });
    $dropzoneInput.on("dragenter", (function(_this) {
      return function(event) {
        return $dropzone.addClass('c-attachments__dropzone--hover');
      };
    })(this));
    $dropzoneInput.on("dragleave", (function(_this) {
      return function(event) {
        return $dropzone.removeClass('c-attachments__dropzone--hover');
      };
    })(this));
    return $dropzoneInput.on('change', (function(_this) {
      return function(event) {
        var file, i, len, ref;
        $dropzone.removeClass('c-attachments__dropzone--hover');
        ref = event.target.files;
        for (i = 0, len = ref.length; i < len; i++) {
          file = ref[i];
          if (accept && !validateType(file, accept)) {
            break;
          }
          if (maxSize && file.size > maxSize) {
            alert("Fichier trop lourd: " + file.name);
            break;
          }
          appendFile($component, $list, file, itemsCounter, model);
          itemsCounter++;
        }
        return $dropzoneInput.val('');
      };
    })(this));
  });
});

// ---
// generated by coffee-script 1.9.2
